/* * 업무구분: 수익증권>파일재전송>전자문서재전송 * 화 면 명: MSPFS330M * 화면설명: 전자문서재전송 * 작 성 일: 2023.10.16 * 작 성 자: 유동훈 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <!-- <div class="column w100p gap12">
            <div class="row w100p"> -->
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 처리기간 </label>
              <mo-date-picker
                class="w130"
                disabled
                v-model="ds_search.cal_startDt"
                :bottom="false"
                ref="cal_startDt"
                :class="ds_error.cal_startDt ? 'error' : ''"
              />
              <i> ~ </i>
              <mo-date-picker
                class="w130"
                disabled
                v-model="ds_search.cal_endDt"
                :bottom="false"
                ref="cal_endDt"
                :class="ds_error.cal_endDt ? 'error' : ''"
              />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_init()"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchCvrList()"> 조회 </mo-button>
              <mo-button primary @click="fn_tranCvrList()"> 처리 </mo-button>
            </div>
          </div>
          <!-- </div>
          </div> -->
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <p class="desc02 mb-1"><em class="colorR">파일은 오류 시점부터 D+7까지만 보관됩니다.</em></p>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th><mo-checkbox class="vaBottom" v-model="isSelectAll" @input="fn_selectAllItems" /></th>
                <th>커버발행번호</th>
                <th>고객명</th>
                <th>커버유형코드</th>
                <th>회사보관용</th>
                <th>고객보관용</th>
                <th>전송일시</th>
                <th>알림톡</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_searchList" :key="i">
                <td v-if="row.docCo != null && row.tranYn != 'Y' && row.todayYn == 'Y'">
                  <mo-checkbox v-model="row.checkYn" @input="fn_selectItem" />
                </td>
                <td v-if="row.docCo == null || row.tranYn == 'Y' || row.todayYn != 'Y'"></td>
                <td>{{ row.cover_pbl_no }}</td>
                <td>{{ row.fnd_cust_nm }}</td>
                <td v-if="row.docCu != null && row.tranYn == 'Y' && (row.cover_tp === '1' || row.cover_tp === '1-1' || row.cover_tp === '1-2')">
                  <mo-dropdown :items="ds_coverTpIn" class="w200" v-model="row.cover_tp" />
                </td>
                <td v-if="row.docCu == null || row.tranYn != 'Y' || ['2', '3'].indexOf(row.cover_tp) > -1">{{ row.cover_tp_nm }}</td>
                <td>{{ row.docCo }}</td>
                <td>{{ row.docCu }}</td>
                <td>{{ row.trtDtm | dateForamt }}</td>
                <td v-if="row.docCu != null && row.tranYn == 'Y'"><mo-button @click="fn_sendTalk(row)">재발송</mo-button></td>
                <td v-if="row.docCu == null || row.tranYn != 'Y'"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-pagination mt-3">
          <span class="float-left"> 총 {{ $bizUtil.numberWithCommas(ds_searchList.length) }} 건 </span>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
  import FSFileUtil from '~/src/ui/fs/comm/FSFileUtil'
  import FSDocUtil from '~/src/ui/fs/comm/FSDocUtil'
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSDateUtil from '~/src/ui/fs/comm/FSDateUtil'

  import TSComDate from '~/src/ui/ts/comm/TSComDate'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPFS330M',
    screenId: 'MSPFS330M',
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$nextTick(() => {
        this.fn_searchCvrList()
      })
    },
    mounted() {
      this.fn_create()
      this.$bizUtil.insSrnLog('MSPTS330M')
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: '문서재전송',
          step: ''
        },

        pAlertPopupObj: {}, // 팝업 객체

        ds_cvrList: [], // 커버목록
        ds_docList: [], // 문서목록
        ds_searchList: [], // 검색목록
        ds_tranList: [], // 전송목록
        ds_search: {}, // 문서검색조건

        ds_coverTp: [], // 커버유형코드
        ds_coverTpIn: [], // 커버유형코드
        isSelectAll: false,
        isSuccessAll: false,
        ds_error: {
          cal_startDt: false,
          cal_endDt: false
        },

        ds_basInfo: this.getStore('fsStore').getters.getBasInfo.data,

        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
        g_eaiId: ''
      }
    },

    /***********************************************************************************
     * filters 함수 정의 영역                                                         *
     ***********************************************************************************/
    filters: {
      dateForamt(val) {
        if (FSCommUtil.gfn_isNull(val)) return ''
        return val.substr(0, 4) + '-' + val.substr(4, 2) + '-' + val.substr(6, 2)
      }
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_create
       * 설명       : 화면 생성
       ******************************************************************************/
      async fn_create() {
        // let busyDate = await FSDateUtil.gfn_getSalesYmd(this, 1)
        let busyDate = this.ds_basInfo.busyDate
        this.ds_search.cal_startDt = FSCommUtil.gfn_getFormat(TSComDate.gfn_minusDate(busyDate, 7), '@@@@-@@-@@')
        this.ds_search.cal_endDt = FSCommUtil.gfn_getFormat(busyDate, '@@@@-@@-@@')

        this.ds_coverTp.push({ cd: '1', cdNm: '신규서류' })
        this.ds_coverTp.push({ cd: '2', cdNm: '매입/환매' })
        this.ds_coverTp.push({ cd: '3', cdNm: '사고신고서' })

        this.ds_coverTpIn.push({ value: '1', text: '신규서류(신규계좌개설)' })
        this.ds_coverTpIn.push({ value: '1-1', text: '신규서류(신규펀드가입)' })
        this.ds_coverTpIn.push({ value: '1-2', text: '신규서류(신규연금계좌개설)' })
        //    	  this.ds_coverTpIn.push({value:"2"  ,text:"매입/환매"})
        //    	  this.ds_coverTpIn.push({value:"3"  ,text:"사고신고서"})
      },

      /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
       ******************************************************************************/
      fn_init() {
        this.ds_cvrList = []
        this.ds_docList = []
        this.ds_searchList = []
        this.ds_tranList = []

        this.isSelectAll = false
      },

      /******************************************************************************
       * Function명 : fn_searchCvrList
       * 설명       : 커버발행목록조회
       ******************************************************************************/
      fn_searchCvrList() {
        this.fn_init()

        this.ds_search.bfcer_accn_no = '' // 수익증권 계좌번호
        this.ds_search.bfcer_cover_stat_cd = '' // 수익증권표지상태코드
        this.ds_search.bfcer_cust_no = '' // 고객번호
        this.ds_search.cover_tp = '' // 커버유형
        this.ds_search.dept_cd = '' // 처리지점코드
        this.ds_search.from_dt = this.fn_replace(this.ds_search.cal_startDt, '-', '') // 시작년월일
        this.ds_search.to_dt = this.fn_replace(this.ds_search.cal_endDt, '-', '') // 종료년월일
        this.ds_search.work_empno = this.ds_basInfo.brcd === '905' ? '' : this.ds_basInfo.usid // 작업자 사번

        this.g_eaiId = 'C391_F10570273_S'
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_search

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570273, this.fn_error)
      },

      /***************************************************************************************
       * Function명 : fn_callBack_F10570273
       * 설명       : 커버발행목록조회 후 콜백
       *****************************************************************************************/
      fn_callBack_F10570273(res) {
        const resData = Object.assign({}, res.data)

        if (resData.co070500_O_00VO == null || resData.co070500_O_00VO.length == 0) {
          this.fn_AlertPopup(0, { content: '처리 대상이 없습니다.' })
          return false
        }

        this.ds_cvrList = resData.co070500_O_00VO
        this.fn_searchDocList()
      },

      /******************************************************************************
       * Function명 : fn_searchDocList
       * 설명       : 전자문서파일목록조회
       ******************************************************************************/
      fn_searchDocList() {
        let param = {
          //          busnScCd : '05',
          busnScCd: 'FS'
        }
        FSFileUtil.gfn_searchDocumentList(this, param, this.fn_callBack_searchDocList)
      },

      /******************************************************************************
       * Function명 : fn_callBack_searchDocList
       * 설명       : 전자문서파일목록조회 후 콜백
       ******************************************************************************/
      fn_callBack_searchDocList(res) {
        // 미전송리스트 추출
        this.ds_searchList = []
        for (let i = 0; i < this.ds_cvrList.length; i++) {
          if (FSCommUtil.gfn_isNull(this.ds_cvrList[i].cover_pbl_no)) {
            continue
          }
          if (FSCommUtil.gfn_isNull(this.ds_cvrList[i].fnd_cust_nm)) {
            continue
          }
          if (this.ds_cvrList[i].cover_pbl_no.substr(14, 1) != '9') {
            continue
          }

          let temp = this.ds_cvrList[i]
          if (temp.cover_tp === '') {
            temp.cover_tp_nm = ''
          } else {
            temp.cover_tp_nm = this.ds_coverTp.filter((p) => p.cd == temp.cover_tp)[0].cdNm
          }
          temp.rowNo = this.ds_searchList.length
          this.ds_searchList.push(temp)
        }

        // 전자문서 적용
        let busyDate = this.ds_basInfo.busyDate
        this.ds_docList = res.dcfsEltrnSignDoctSendListVO
        for (let i = 0; i < this.ds_searchList.length; i++) {
          let temp = this.ds_docList.filter((p) => p.eltrnDoctId == this.ds_searchList[i].cover_pbl_no)[0]
          if (FSCommUtil.gfn_isNull(temp)) {
            continue
          }

          this.ds_searchList[i].docCo = FSCommUtil.gfn_trim(temp.docCoYn).length == 0 ? null : '○'
          this.ds_searchList[i].docCu = FSCommUtil.gfn_trim(temp.docCuYn).length == 0 ? null : '○'
          this.ds_searchList[i].docPath = FSCommUtil.gfn_trim(temp.docPath).length == 0 ? null : temp.docPath
          this.ds_searchList[i].todayYn = temp.docPath.indexOf(busyDate) < 0 ? null : 'Y'
          this.ds_searchList[i].trtDtm = FSCommUtil.gfn_trim(temp.trtDtm).length == 0 ? null : temp.trtDtm
          this.ds_searchList[i].tranYn = FSCommUtil.gfn_trim(temp.trtDtm).length == 0 ? null : 'Y'
        }
      },

      /******************************************************************************
       * Function명 : fn_tranCvrList
       * 설명       : 전송 처리 리스트 추출
       ******************************************************************************/
      fn_tranCvrList() {
        this.isSuccessAll = true
        this.ds_tranList = []
        for (let i = 0; i < this.ds_searchList.length; i++) {
          if (this.ds_searchList[i].checkYn == true) {
            let temp = this.ds_searchList[i]
            temp.execNum = this.ds_tranList.length
            this.ds_tranList.push(JSON.parse(JSON.stringify(temp)))
          }
        }

        if (this.ds_tranList.length == 0) {
          this.fn_AlertPopup(0, { content: '선택 항목이 없습니다.' })
          return
        }

        this.fn_tran(0)
      },

      /******************************************************************************
       * Function명 : fn_tran
       * 설명       : 파일재전송
       ******************************************************************************/
      fn_tran(row) {
        // 비동기 실행을 막기위해 수행
        this.fn_selectItem()
        if (row >= this.ds_tranList.length) {
          if (this.isSuccessAll) {
            window.vue.getStore('confirm').dispatch('ADD', '전자서식 전송처리 되었습니다.')
            this.fn_searchCvrList()
            return
          }
        }

        let obj = this.ds_tranList[row]
        this.fn_SerivceEltrnSignCmpltBatch(obj)

        // 알림톡전송
        // this.fn_sendTalk(obj)
      },

      /************************************************************************************************
       * Function명  : fn_SerivceEltrnSignCmplt
       * 설명        : 전자서명 완료 처리
       ************************************************************************************************/
      fn_SerivceEltrnSignCmpltBatch(obj) {
        const pParams = {
          busnScCd: 'fs',
          eltrnDoctId: obj.cover_pbl_no,
          eltrnDoctFilePathNm: obj.docPath,
          timeout: '600000' // 기본 타이머 10분
        }

        const trnstId = 'txTSSFS02P7'
        this.post(this, pParams, trnstId, 'S')
          .then((response) => {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.trnsRsltCd == '0') {
                // 완료
                this.ds_searchList[obj.rowNo].checkYn = false
                this.fn_selectItem()

                this.fn_tran(obj.execNum + 1)
                return
              }

              window.vue
                .getStore('confirm')
                .dispatch(
                  'ADD',
                  obj.fnd_cust_nm +
                    '의 [' +
                    obj.cover_pbl_no +
                    '] 전자서식 작성이 실패하였습니다. 업무보완바랍니다. (오류코드 : ' +
                    response.body.trnsRsltCd +
                    ')'
                )
            } else {
              // 정상처리가 아닌경우
              return response.msgComm.msgDesc
              this.fn_AlertPopup(0, { content: response.msgComm.msgDesc })
            }
          })
          .catch(function(error) {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            window.vue.error(error)
          })
      },

      /******************************************************************************
       * Function명 : fn_sendTalk
       * 설명       : 알림톡전송
       ******************************************************************************/
      async fn_sendTalk(obj) {
        let ds_sendData = await this.fn_getTalkData(obj)

        // 커버정보
        let cvrPblInfo = {}
        cvrPblInfo.cover_pbl_no = obj.cover_pbl_no
        cvrPblInfo.cover_pbl_yn = 'Y'
        this.getStore('fsStore').getters.getBasInfo.data.cvrPblInfo = cvrPblInfo

        // 고객정보
        let custInfo = {}
        custInfo = ds_sendData
        this.getStore('fsStore').getters.getBasInfo.data.custInfo = custInfo

        // 문서정보
        let prdtObj = {}
        if (obj.cover_tp === '1') {
          prdtObj.name = '신규계좌개설'
          prdtObj.code = '06760' // 신규
        }
        if (obj.cover_tp === '1-1') {
          prdtObj.name = '신규펀드가입'
          prdtObj.code = '06761' // 신규
        }
        if (obj.cover_tp === '1-2') {
          prdtObj.name = '신규연금계좌개설'
          prdtObj.code = '06764' // 신규
        }
        if (obj.cover_tp === '2') {
          prdtObj.name = '매입/환매'
          prdtObj.code = '06762' // 변경
        }
        if (obj.cover_tp === '3') {
          prdtObj.name = '사고신고서'
          prdtObj.code = '06762' // 해지
        }

        this.getStore('fsStore').getters.getBasInfo.data.prdtObj = prdtObj
        this.$bizUtil.fsUtils
          .mobpsPblProc(this)
          .then(function(response) {
            if (response.body == null) {
              window.vue.getStore('confirm').dispatch('ADD', '알림톡 재 발송 중 오류가 발생하였습니다.')
            } else {
              window.vue.getStore('confirm').dispatch('ADD', obj.fnd_cust_nm + '의 [' + obj.cover_pbl_no + ']의 알림톡을 재 발송하였습니다.')
            }
          })
          .catch(function(error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
       * Function명 : fn_getTalkData
       * 설명       : 고객 또는 계약정보조회 - 알림톡 전화번호 및 상품명 확인 목적
       ******************************************************************************/
      async fn_getTalkData(obj) {
        let ds_search = {}
        ds_search.tacno = obj.bfcer_accn_no.substr(0, 7)
        ds_search.bnkbSeq = obj.bfcer_accn_no.substr(7, 3)
        ds_search.accSeq = obj.bfcer_accn_no.substr(10, 3)
        ds_search.ctno = Number(ds_search.accSeq)

        let ds_sendData = {}
        let ds_custInfo = await this.fn_getCustInfo(obj)

        ds_sendData.rrnAesEncr = ds_custInfo.rlnm_cnf_no_encr
        ds_sendData.custNm = ds_custInfo.fnd_cust_nm
        ds_sendData.celph = ds_custInfo.bman_celph_dstno_encr + ds_custInfo.bman_celph_tlnum_no_encr + ds_custInfo.bman_celph_bkno_sno_encr

        if (obj.bfcer_accn_no.length == 13 && obj.cover_tp.indexOf('1') >= 0) {
          let ds_contractInfo = await this.fn_getContractInfo(obj)

          console.log('obj : ', obj)
          console.log('ds_contractInfo : ', ds_contractInfo)
          if (!FSCommUtil.gfn_isNull(ds_contractInfo) && !FSCommUtil.gfn_isNull(ds_contractInfo.ReportOutList[0])) {
            if (ds_contractInfo.ReportOutList[0].fund_cd !== '') {
              ds_sendData.fundCd1 = ds_contractInfo.ReportOutList[0].fund_cd // 신규거래신청서(펀드가입신청서)
            } else {
              // 연금계좌 신규거래신청서
              ds_sendData.fundCd1 = ds_contractInfo.ReportOutList[0].fund_cd1
              ds_sendData.fundCd2 = ds_contractInfo.ReportOutList[0].fund_cd2
            }
          }
        }
        return ds_sendData
      },

      /******************************************************************************
       * Function명 : fn_getCustInfo
       * 설명       : 고객정보조회
       ******************************************************************************/
      async fn_getCustInfo(obj) {
        let ds_search = {}
        ds_search.bfcer_cust_no = obj.bfcer_cust_no

        this.g_eaiId = 'C391_F10570293_S'
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = ds_search

        let res = await FSServiceUtil.invokeInDirect(this.eaiCommObj)

        const resData = res.data
        if (FSCommUtil.gfn_trim(resData.error_msg) != '' && resData.error_msg.substring(0, 6) != '809900') {
          let t_popupObj = { content: FSMessageUtil.commMessage([], resData.error_msg)[resData.error_msg.substring(0, 6)] }
          this.fn_AlertPopup(0, t_popupObj)
          return
        }

        return resData
      },

      /******************************************************************************
       * Function명 : fn_getContractInfo
       * 설명       : 계약정보조회
       ******************************************************************************/
      async fn_getContractInfo(obj) {
        let ds_search = {}
        let ds_searchReport = {}

        ds_search.bfcer_busn_trt_id = obj.tr_menu //
        ds_search.bfcer_busn_trt_sc_cd = 'I' //
        ds_search.bfcer_cust_no = obj.bfcer_cust_no //
        ds_search.frm_issnc_ymd = obj.frm_issnc_ymd //
        ds_search.issnc_hstr_sno = obj.issnc_hstr_sno //
        ds_search.rprt_frm_no = obj.rprt_frm_no //

        ds_searchReport.reportParam = JSON.stringify(ds_search)
        ds_searchReport.reportUrl = 'reportCO070500_Info'

        let resData = await FSInfoUtil.getReportInfo(this, ds_searchReport)
        return resData
      },

      /******************************************************************************
       * Function명 : fn_selectAllItems
       * 설명       : 리스트 전체 선택
       ******************************************************************************/

      fn_selectAllItems() {
        for (let i = 0; i < this.ds_searchList.length; i++) {
          if (this.ds_searchList[i].docCo != null && this.ds_searchList[i].tranYn != 'Y' && this.ds_searchList[i].todayYn == 'Y') {
            this.ds_searchList[i].checkYn = this.isSelectAll
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_selectItem
       * 설명       : 선택
       ******************************************************************************/

      fn_selectItem() {
        let selectAll = true
        for (let i = 0; i < this.ds_searchList.length; i++) {
          if (this.ds_searchList[i].tranYn == 'Y') {
            continue
          }
          if (FSCommUtil.gfn_isNull(this.ds_searchList[i].docCo)) {
            continue
          }
          if (this.ds_searchList[i].checkYn != true) {
            selectAll = false
            break
          }
        }

        this.isSelectAll = selectAll
        this.$forceUpdate()
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/

      fn_AlertPopup(type, pPopupObj) {
        if (!FSCommUtil.gfn_isNull(pPopupObj)) {
          this.pAlertPopupObj.content = pPopupObj.content
          this.pAlertPopupObj.confirm = false
          this.pAlertPopupObj.cancel = true
          this.pAlertPopupObj.type = 0
          this.pAlertPopupObj.contentTitle = ''

          if (!pPopupObj.cancel) {
            this.pAlertPopupObj.cancel = pPopupObj.cancel
          }
          if (pPopupObj.confirm) {
            this.pAlertPopupObj.confirm = pPopupObj.confirm
            this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            this.pAlertPopupObj.confirmData = pPopupObj.confirmData
          }
          if (pPopupObj.closeFunc) {
            this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            this.pAlertPopupObj.closeData = pPopupObj.closeData
          }
          if (!FSCommUtil.gfn_isNull(type) && type != 0) {
            this.pAlertPopupObj.type = type
          }
          if (pPopupObj.contentTitle) {
            this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
          }
        }
        this.$refs.alertPopup.fn_Open()
      },

      fn_replace(pStr, searchStr, replaceStr) {
        let sReturnValue = pStr.trim()
        return sReturnValue.split(searchStr).join(replaceStr)
      }
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {}
  }
</script>

<style lang="scss"></style>

<style scoped></style>
